module.exports = [{
      plugin: require('../node_modules/@reflexjs/gatsby-plugin-metatags/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[],"remarkPlugins":[],"extensions":[".mdx"],"defaultLayouts":{},"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Reflex - Supercharge your Gatsby sites.","short_name":"Reflex","start_url":"/","background_color":"#fff","theme_color":"#000","display":"standalone","icon":"content/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5b9e3769e32692a646ea64d8e2e28779"},
    },{
      plugin: require('../node_modules/@reflexjs/gatsby-plugin-metatags/gatsby-browser.js'),
      options: {"plugins":[],"global":{"title":"Reflex - Supercharge your Gatsby sites.","description":"A collection of themes and plugins to help you build Gatsby sites faster.","image":"thumbnail.jpg"},"types":["MdxPage","MdxPost","MdxDoc","MdxTutorial","MdxStyleguide","MdxVideo"],"paths":[{"pathname":"/library{,/**}","defaults":{"title":"Blocks library","description":"Accessible components and blocks to build Gatsby sites."}}]},
    },{
      plugin: require('../plugins/gatsby-plugin-docsearch/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"b8a382b739dc2dc5ecc6703a923afd8b","indexName":"reflexjs","inputSelector":"#docsearch-input","debug":"false"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
