import * as React from "react";
import { Article, H2, P, Div, Grid, Container } from "@reflexjs/components";
import { Link } from "@reflexjs/gatsby-theme-core";
import { Image } from "@reflexjs/gatsby-plugin-image";
import { useVideo } from "@reflexjs/gatsby-theme-video";
export default {
  React,
  Article,
  H2,
  P,
  Div,
  Grid,
  Container,
  Link,
  Image,
  useVideo
};